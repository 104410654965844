<template>
  <div class="registration-form">
    <div class="d-flex align-center branding">
      <div class="logo">
        <img :src="require(`@/assets/images/logo-light.png`)" />
      </div>
    </div>
    <div class="steps">
      <v-card width="620" class="mx-auto border-radius">
        <v-window v-model="step">
          <v-window-item :value="0">
            <v-form data-vv-scope="register" @submit.prevent>
              <v-layout row wrap class="py-5 px-9">
                <v-flex xs12 class="mb-4 text-center sw-h5">
                  {{ $t("register.register.title") }}
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="user.first_name"
                    v-validate.disable="'required'"
                    data-vv-name="first_name"
                    :data-vv-as="$t('common.first_name')"
                    :label="$t('common.first_name')"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    :error-messages="errors.collect('register.first_name')"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="user.last_name"
                    v-validate.disable="'required'"
                    data-vv-name="last_name"
                    :data-vv-as="$t('common.last_name')"
                    :label="$t('common.last_name')"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    :error-messages="errors.collect('register.last_name')"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="user.email"
                    v-validate.disable="'required|email'"
                    data-vv-name="email"
                    :data-vv-as="$t('common.email')"
                    :label="$t('common.email')"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    :error-messages="errors.collect('register.email')"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex xs4>
                      <CountryCodeSelector
                        v-model="user.phone_code"
                        v-validate.disable="user.phone ? 'required' : ''"
                        data-vv-name="phone_code"
                        :data-vv-as="$t('common.phone_code')"
                        :label="''"
                      />
                      <ErrorMessages
                        :error-messages="errors.collect('register.phone_code')"
                      />
                    </v-flex>
                    <v-flex xs8 class="pl-5">
                      <v-text-field
                        v-model="user.phone"
                        v-validate.disable="phoneNumberRules"
                        data-vv-name="phone"
                        :data-vv-as="$t('common.phone')"
                        :label="$t('common.phone')"
                        hide-details
                      ></v-text-field>
                      <ErrorMessages
                        :error-messages="errors.collect('register.phone')"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 class="mb-4">
                  <v-checkbox
                    v-model="generalTermsCheck"
                    v-validate.disable="'required:true'"
                    data-vv-name="general_terms"
                    :data-vv-as="$t('common.general_terms')"
                    hide-details
                  >
                    <template v-slot:label>
                      <div
                        @click.stop="generalTermsDialog = true"
                        class="black--text"
                      >
                        <u>{{ $t("common.general_terms") }}</u>
                      </div>
                    </template>
                  </v-checkbox>
                  <ErrorMessages
                    :error-messages="errors.collect('register.general_terms')"
                  />
                </v-flex>
                <v-flex
                  xs12
                  v-if="serverErrorMesssage"
                  class="mb-4 text-center"
                >
                  <div class="red--text sw-caption">
                    {{ serverErrorMesssage }}
                  </div>
                </v-flex>
                <v-flex xs12 class="text-center">
                  <v-btn
                    round
                    large
                    class="sw-accent-bg sw-on-accent text-none"
                    @click="register"
                    :loading="isVuexLoading('register')"
                    >{{ $t("common.continue") }}</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-form>
          </v-window-item>
          <v-window-item :value="1">
            <v-form data-vv-scope="reminder" @submit.prevent>
              <v-layout row wrap class="py-5 px-9">
                <v-flex xs12 class="mb-4 text-center sw-h5">
                  {{ $t("register.remind.title") }}
                </v-flex>
                <v-flex xs12 class="mb-4 caption text-center">
                  {{ $t("register.remind.subtitle") }}
                </v-flex>
                <v-flex xs12 class="mb-4">
                  <v-text-field
                    v-model="reminderEmail"
                    v-validate.disable="'required|email'"
                    data-vv-name="email"
                    :data-vv-as="`Email`"
                    :label="`Email`"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    :error-messages="errors.collect('reminder.email')"
                  />
                </v-flex>
                <v-flex
                  xs12
                  v-if="serverErrorMesssage"
                  class="mb-4 text-center"
                >
                  <div class="red--text sw-caption">
                    {{ serverErrorMesssage }}
                  </div>
                </v-flex>
                <v-flex xs12 class="text-center">
                  <v-btn
                    type="submit"
                    round
                    large
                    class="sw-accent-bg sw-on-accent text-none"
                    @click="reminder"
                    :loading="isVuexLoading('reminder')"
                    >{{ $t("common.send") }}</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-form>
          </v-window-item>
          <v-window-item :value="2">
            <v-form data-vv-scope="login" @submit.prevent>
              <v-layout row wrap class="py-5 px-9">
                <v-flex xs12 class="mb-4 text-center sw-h5">
                  {{ $t("register.login.title") }}
                </v-flex>
                <v-flex xs12 class="mb-4 sw-caption text-center">
                  {{ $t("register.login.subtitle") }}
                </v-flex>
                <v-flex xs12 class="mb-4">
                  <v-text-field
                    v-model="verificationCode"
                    v-validate.disable="'required|digits:4'"
                    data-vv-name="pin"
                    :data-vv-as="$t('register.login.pin')"
                    :label="$t('register.login.pin')"
                    hide-details
                  ></v-text-field>
                  <ErrorMessages
                    :error-messages="errors.collect('login.pin')"
                  />
                </v-flex>
                <v-flex xs12 class="mb-4 sw-caption text-center">
                  <v-btn
                    type="submit"
                    round
                    large
                    class="sw-accent-bg sw-on-accent text-none"
                    @click="login"
                    :loading="isVuexLoading('login')"
                    >{{ $t("register.login.verify") }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 class="text-center sw-caption">
                  {{ $t("register.login.resend_pin") }}
                  <a @click.stop="step = 1" class="sw-accent">{{
                    $t("register.login.press_here")
                  }}</a>
                </v-flex>
              </v-layout>
            </v-form>
          </v-window-item>
          <v-window-item :value="3">
            <v-layout row wrap class="py-5 px-9">
              <v-flex xs12 class="mb-4 text-center sw-h5">
                {{ $t("register.finish.title") }}
              </v-flex>
              <v-flex xs12 class="text-center">
                <v-btn
                  type="submit"
                  round
                  large
                  class="sw-accent-bg sw-on-accent text-none"
                  @click="storeUser"
                  >{{ $t("common.continue") }}</v-btn
                >
              </v-flex>
            </v-layout>
          </v-window-item>
        </v-window>
      </v-card>
    </div>
    <v-dialog v-model="generalTermsDialog" persistent width="700">
      <v-card class="border-radius">
        <div class="sw-py-3 text-center sw-h5">
          {{ $t("common.terms_and_conditions") }}
        </div>
        <div class="sw-pr-1">
          <overlay-scrollbars :options="scrollbarOptions">
            <div style="max-height: 300px" class="sw-px-4">
              <v-card-text>
                <div v-html="generalTerms"></div>
              </v-card-text>
            </div>
          </overlay-scrollbars>
        </div>
        <div class="sw-py-3 text-center">
          <v-btn
            round
            large
            class="white sw-accent text-none"
            @click="generalTermsDialog = false"
          >
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn
            round
            large
            class="sw-accent-bg sw-on-accent text-none"
            @click="acceptGeneralTerms"
          >
            {{ $t("common.accept") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CountryCodeSelector from "@/components/CustomFields/CountryCodeSelector.vue";
import moment from "moment-timezone";

export default {
  data: () => ({
    serverErrorMesssage: "",
    step: 0,
    user: {
      phone_code: "45",
    },
    tempUserData: {},
    generalTerms: "",
    generalTermsCheck: false,
    generalTermsDialog: false,
    reminderEmail: "",
    verificationCode: "",
    scrollbarOptions: {
      scrollbars: { autoHide: "leave" },
      overflowBehavior: {
        x: "scroll",
      },
      sizeAutoCapable: true,
    },
  }),
  computed: {
    phoneNumberRules() {
      return process.env.VUE_APP_PHONE_NUMBER_RULES || "numeric|min:8|max:12";
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  components: {
    CountryCodeSelector,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.currentUser && vm.currentUser.id) {
        return next({
          name: "home",
        });
      }

      if (process.env.VUE_APP_REGISTRATION !== "true") {
        return next({
          name: "authorize",
        });
      }

      next();
    });
  },
  mounted() {
    this.getTermsAndConditions();
  },
  methods: {
    acceptGeneralTerms() {
      this.generalTermsCheck = true;
      this.generalTermsDialog = false;
    },
    getTermsAndConditions() {
      this.$api.settings.public("global.terms_and_conditions").then(
        (response) => {
          if (!response || !response.data.data.value) {
            return;
          }
          this.generalTerms = response.data.data.value;
        },
        () => {},
      );
    },
    async register() {
      const isValid = await this.$validator.validateAll("register");

      if (!isValid) return;

      this.startVuexLoading("register");

      const registerSpecs = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        phone_code: this.user.phone ? this.user.phone_code : "",
        phone: this.user.phone,
        password: Math.random().toString(36),
        terms_accepted_at: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      };

      const reminderParams = {
        email: this.user.email,
      };

      try {
        await this.$api.auth.group_register(registerSpecs);
        await this.$api.auth.reminder(reminderParams);

        this.endVuexLoading("register");
        this.serverErrorMesssage = "";
        this.step = 2;
      } catch (error) {
        this.endVuexLoading("register");

        if (!error.response || !error.response.data) {
          this.serverErrorMesssage = this.$t("serverError");
          return;
        }

        this.serverErrorMesssage = error.response.data.error.message;
      }
    },
    async reminder() {
      if (!this.reminderEmail) return;

      const isValid = await this.$validator.validateAll("reminder");

      if (!isValid) return;

      this.startVuexLoading("reminder");

      const params = {
        email: this.user.email,
      };

      this.$api.auth.reminder(params).then(
        (response) => {
          this.endVuexLoading("reminder");
          this.serverErrorMesssage = "";
          if (response.status !== 200) return;
          this.step = 2;
        },
        (error) => {
          this.endVuexLoading("reminder");
          if (!error.response || !error.response.data) {
            this.serverErrorMesssage = this.$t("serverError");
            return;
          }
          this.serverErrorMesssage = error.response.data.error.message;
        },
      );
    },
    async login() {
      const isValid = await this.$validator.validateAll("login");

      if (!isValid) return;

      this.startVuexLoading("login");

      const params = {
        email: this.user.email || this.reminderEmail,
        pin: this.verificationCode,
      };

      this.$api.auth.login(params).then(
        (response) => {
          this.endVuexLoading("login");

          if (response.status !== 200) return;

          this.tempUserData = response.data.data;

          this.step = 3;
        },
        () => {
          this.endVuexLoading("login");

          this.$validator.errors.add({
            field: "pin",
            msg: this.$t("register.login.error"),
            scope: "login",
          });
        },
      );
    },
    storeUser() {
      if (!this.tempUserData.id) return;

      this.$router
        .replace({
          name: "home",
        })
        .catch(() => {});

      this.$store.commit("SET_TOKEN", this.tempUserData.token);
      this.$store.commit("SET_USER", this.tempUserData);
    },
  },
};
</script>

<style lang="scss" scoped>
.registration-form {
  .branding {
    .logo img {
      max-width: 250px;
    }
    width: 100%;
    min-height: 200px;
    padding: 0 60px;
    position: fixed;
  }
  .steps {
    padding: 200px 0;
  }
  min-height: 100%;
  background-image: url("~@/assets/images/register-bg.png");
  background-position: center;
  background-size: cover;
}
</style>
